
export default (t) => {
    const createEntry = (name, title, description, type, link) => ({
        name,
        title: t(title),
        description: t(description),
        type,
        link,
    });

    return [
        createEntry(
            'metadefender-browser-extension',
            'AppSec - Browser Extensions',
            'Browser extension for scanning with MetaDefender',
            'extension',
            'https://chrome.google.com/webstore/detail/opswat-file-security-for/fjampemfhdfmangifafmianhokmpjbcj'
        ),
        createEntry(
            'metadefender-gatling-test',
            'Test Automation - Client Agent',
            'Using Gatling framework to test MetaDefender Core performance',
            'client',
            'https://github.com/OPSWAT/metadefender-gatling-test'
        ),
        createEntry(
            'metadefender-teamcity-plugin',
            'DevSecOPs - Client Agent',
            'MetaDefender TeamCity plugin',
            'client',
            'https://github.com/OPSWAT/metadefender-teamcity-plugin'
        ),
        createEntry(
            'metadefender-menlo-integration',
            'AppSec - Client Agent',
            'MetaDefender integration to Menlo Security',
            'client',
            'https://github.com/OPSWAT/metadefender-menlo-integration'
        ),
        createEntry(
            'metadefender-cloudformation',
            'DevOPs - Client Agent',
            'CloudFormation Templates to generate all resources needed to run the MetaDefender AMI',
            'client',
            'https://github.com/OPSWAT/metadefender-cloudformation'
        ),
        createEntry(
            'mdcloud-go',
            'AppSec - Client Agent',
            'MetaDefender Cloud CLI in GOLang',
            'client',
            'https://github.com/OPSWAT/mdcloud-go'
        ),
        createEntry(
            'Salesforce App',
            'AppSec - Client Agent',
            'OPSWAT Cloud Security for Salesforce',
            'client',
            'https://appexchange.salesforce.com/appxListingDetail?listingId=a0N3A00000FeG3eUAF'
        ),
        createEntry(
            'Process Hacker',
            'AppSec - Desktop Apps',
            'Monitor system resources, debug software and detect malware',
            'desktop',
            '/'
        ),
    ];
};

